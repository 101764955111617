<script>
import AppCard from '@/components/card/Card.vue'

import ModuleButton from './module-selection-panel-components/ModuleButton.vue'

export default {
  components: {
    AppCard,
    ModuleButton
  },
  data() {
    return {
      moduleSelections: [
        {
          icon: 'person',
          label: 'ผู้ใช้งาน',
          link: '/admin/user-logon-masters'
        },
        {
          icon: 'people',
          label: 'กลุ่มผู้ใช้งาน',
          link: '/admin/group-logon-masters'
        },
        {
          icon: 'unlock',
          label: 'กำหนดสิทธิ์การใช้งาน',
          link: '/admin/system-menu-accesses'
        },
        {
          icon: 'shop',
          label: 'จัดการสาขา',
          link: '/admin/branches'
        }
      ]
    }
  }
}
</script>

<template>
  <div class="mt-3">
    <app-card>
      <b-row>
        <b-col>
          <span class="text-lg font-weight-bold">
            จัดการโมดูลในระบบ
          </span>
        </b-col>
      </b-row>
      <b-row class="pt-3">
        <b-col
          v-for="item in moduleSelections"
          :key="item.label"
          cols="12"
          sm="6"
          lg="4"
          xl="3"
          class="mt-3"
        >
          <module-button
            :labelProp="item.label"
            :iconProp="item.icon"
            :linkProp="item.link"
          ></module-button>
        </b-col>
      </b-row>
    </app-card>
  </div>
</template>

<style></style>
