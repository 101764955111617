<script>
import { mapState } from 'vuex'

import MenuBar from './components/MenuBar.vue'
import ModuleSelectionPanel from './components/ModuleSelectionPanel.vue'

export default {
  components: {
    MenuBar,
    ModuleSelectionPanel
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user
    })
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="บริหารจัดการองค์กร"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>
    <!-- แถบเลือก module -->
    <b-row>
      <b-col class="mt-3">
        <module-selection-panel></module-selection-panel>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped></style>
