<script>
export default {
  props: {
    labelProp: String,
    iconProp: String,
    linkProp: String,
    variantProp: String
  },
  methods: {
    buttonClicked() {
      if (this.linkProp) {
        this.$router.push(this.linkProp)
      }
    }
  }
}
</script>

<template>
  <b-button
    :variant="variantProp || 'outline-primary'"
    class="w-100"
    @click="buttonClicked()"
  >
    <div class="text-center">
      <div>
        <b-icon
          :icon="iconProp || 'arrow-right-square'"
          class="icon-style mt-2"
        ></b-icon>
      </div>
      <div class="mt-4">
        <span>{{ labelProp || 'BUTTON' }}</span>
      </div>
    </div>
  </b-button>
</template>

<style scoped>
.icon-style {
  width: 40px;
  height: 40px;
}
</style>
